import styled from '@emotion/styled'
import { ReactNode } from 'react'
import { mediaQuery } from '../../functions/hooks'

const BaseLabel = styled('label')`
  border-radius: 5px;
  font-size: 14px;
  overflow: hidden;
  padding: 4px 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${mediaQuery('sp')} {
    font-size: 10px;
    padding: 2px 4px;
  }
`
const NewLabel = styled(BaseLabel)`
  background-color: #f08e35;
  color: white;
  &.-card {
    font-weight: bold;
    left: 10px;
    position: absolute;
    top: 10px;
    ${mediaQuery('sp')} {
      left: 5px;
      top: 5px;
    }
  }
`
const CategoryLabel = styled(BaseLabel)`
  background-color: #9696d0;
  color: white;
  &.-card {
    font-size: 12px;
    padding: 1px 4px;
  }
`
const EventLabel = styled(BaseLabel)`
  font-size: 12px;
  padding: 1px 4px;
  &.-held-today,
  &.-in-ession {
    background: #f08e35;
    border: 1px solid #f08e35;
    color: white;
  }
  &.-scheduled {
    background: white;
    border: 1px solid #f08e35;
    color: #f08e35;
  }
  &.-open-for-entries {
    background: white;
    border: 1px solid #8dbf6a;
    color: #8dbf6a;
  }
  &.-ended,
  &.-new-entries-closed {
    background: #f2f2f2;
    border: 1px solid #f2f2f2;
    color: #4a4a4a;
  }
`
const OrderLabel = styled(BaseLabel)`
  border-radius: 30px;
  font-size: 16px;
  padding: 6px 10px;
  text-align: center;
  width: 120px;
  ${mediaQuery('sp')} {
    font-size: 14px;
  }
  &.-on_delivery {
    background: #cc3c4d;
    border: 1px solid #cc3c4d;
    color: #ffffff;
  }
  &.-delivered {
    background: #8dbf6a;
    border: 1px solid #8dbf6a;
    color: #ffffff;
  }
  &.-cancel {
    background: #c4c4c4;
    border: 1px solid #c4c4c4;
    color: #ffffff;
  }
  &.-confirmed,
  &.-draft,
  &.-checked,
  &.-ready {
    background: #f08e35;
    border: 1px solid #f08e35;
    color: #ffffff;
  }
`
const RequiredLabel = styled('label')`
  background: #f08e35;
  border-radius: 5px;
  color: white;
  font-size: 12px;
  padding: 0 5px;
`

export const agenda_name = [
  '',
  'held-today', // 本日開催
  'in-ession', // 開催中
  'scheduled', // 開催予定
  'ended', // 開催終了
  'open-for-entries', // 応募受付中
  'new-entries-closed' // 応募終了
]

export type BasicLabelProps = {
  children: ReactNode
  newType?: 'card' | 'content'
  journalType?: 'card' | 'content'
  eventType?: number
  isRequired?: boolean
  order?:
    | 'on_delivery'
    | 'delivered'
    | 'cancel'
    | 'confirmed'
    | 'draft'
    | 'checked'
    | 'ready'
}

export const BasicLabel: React.FC<BasicLabelProps> = ({
  children,
  newType,
  journalType,
  eventType,
  isRequired,
  order
}) => {
  return (
    <>
      {/* 新着ラベル */}
      {newType === 'card' ? (
        <NewLabel className="-card">{children}</NewLabel>
      ) : null}
      {newType === 'content' ? <NewLabel>{children}</NewLabel> : null}

      {/* ジャーナルラベル */}
      {journalType === 'card' ? (
        <CategoryLabel className="-card">{children}</CategoryLabel>
      ) : null}
      {journalType === 'content' ? (
        <CategoryLabel>{children}</CategoryLabel>
      ) : null}

      {/* イベントラベル */}
      {eventType ? (
        <EventLabel className={`-${agenda_name[eventType]}`}>
          {children}
        </EventLabel>
      ) : null}
      {/* 必須ラベル */}
      {isRequired ? <RequiredLabel>{children}</RequiredLabel> : null}
      {/* マルデリ注文ラベル */}
      {order ? (
        <OrderLabel className={`-${order}`}>{children}</OrderLabel>
      ) : null}
    </>
  )
}
