import styled from '@emotion/styled'
import { Swiper } from 'swiper/react'
import { Pagination, Navigation } from 'swiper'
// import 'swiper/css'
// import 'swiper/css/bundle'
import { mediaQuery } from '../../functions/hooks'
import 'swiper/css/navigation'

const CarouselContainer = styled(Swiper)<{ color: string }>`
  width: 100%;

  ${mediaQuery('sp')} {
    max-width: 520px;
    &.-feature {
      .swiper-slide > div > img {
        width: 60vw;
      }
      .swiper-slide > div > p {
        width: 60vw;
      }
    }
  }

  .swiper-slide {
    width: auto;
  }
  .swiper-wrapper {
    margin-bottom: 50px;
  }
  .swiper-pagination {
    bottom: 0px;
  }
  .swiper-pagination-bullet {
    background: #ffffff;
    border: 1px solid;
    border-color: ${({ color }) => color};
  }
  .swiper-pagination-bullet-active {
    background: ${({ color }) => color};
  }
`

type BreakPoints = Record<number, Record<string, number>>
type AutoPlay = {
  delay: number
  disableOnInteraction: boolean
}

type CarouselProps = {
  breakpoints: BreakPoints
  spaceBetween: number
  slidesPerView: number
  children: JSX.Element
  loop?: boolean
  loopAdditionalSlides?: number
  speed?: number
  autoPlay?: AutoPlay
  className?: string
  content?: string
  categoryType?: string
}

/**
 * swiperを利用するためにはswiperとswiperSlideが必要になる
 * Carousel.tsxで提供しているはswiperのラッパーのみ
 * 別途使用するコンポーネントでswiperSlideのインポートが必要になる
 * swiperSlideのラッパーを作成しても読み込み先で動かなかっためswiperのみの提供にした
 */
export const Carousel: React.FC<CarouselProps> = ({
  breakpoints,
  spaceBetween,
  slidesPerView,
  children,
  loop = false,
  loopAdditionalSlides = 1,
  speed = 1000,
  autoPlay = false,
  className,
  content = '#4A4A4A',
  categoryType
}) => {
  return (
    <CarouselContainer
      breakpoints={breakpoints}
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      modules={[Pagination, Navigation]}
      pagination={{
        clickable: true,
        dynamicMainBullets: 2
      }}
      loop={loop}
      loopAdditionalSlides={loopAdditionalSlides}
      speed={speed}
      autoplay={autoPlay}
      className={className}
      lazy
      color={content}
      navigation={{
        // ナビゲーションボタンのカスタムクラス
        nextEl: `#carousel-next-element${
          categoryType ? `-${categoryType}` : ''
        }`,
        prevEl: `#carousel-prev-element${
          categoryType ? `-${categoryType}` : ''
        }`
      }}
    >
      {children}
    </CarouselContainer>
  )
}
