import { ReactNode, useRef, useCallback } from 'react'
import { styled } from '@mui/material/styles'
import ArrowIcon from '../../assets/icon/icon_arrow.svg'

const RoundGradientLink = styled('a')`
  /* stylelint-disable */
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 6px 16px;
  border-radius: 100rem;
  color: black;
  border: solid 3px transparent;
  text-decoration: none;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(
      101deg,
      #bed56e,
      #eac06c,
      #f1b169,
      #fc6456,
      #f94b59,
      #b77dc8
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px white inset;

  &.-grey {
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(101deg, #c4c4c4, #c4c4c4);
  }

  &::before {
    background: rgba(0, 0, 0, 0.2);
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
    z-index: 2;
    border-radius: 100rem;
  }

  &:hover::before {
    opacity: 0.2;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
  }

  .animation-icon {
    transition: 0.5s;
    transform: rotate(0deg);
  }

  &:hover .animation-icon {
    transition: 0.5s;
    transform: rotate(90deg);
  }

  &.-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`

interface LinkRoundProps {
  children: ReactNode
  className?: string
  isReversible?: boolean
  isDisabled?: boolean
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
}

export const LinkRound: React.FC<LinkRoundProps> = ({
  className,
  children,
  isReversible = false,
  isDisabled,
  onClick
}) => {
  const processing = useRef(false)
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (processing.current || isDisabled) {
        event.preventDefault()
        return
      }
      processing.current = true

      if (onClick) onClick(event)

      setTimeout(() => {
        processing.current = false
      }, 3000)
    },
    [onClick, isDisabled]
  )

  const combinedClassName = [
    className,
    isReversible ? '-grey' : '',
    isDisabled ? '-disabled' : ''
  ]
    .filter(Boolean)
    .join(' ')

  return (
    <RoundGradientLink className={combinedClassName} onClick={handleClick}>
      {isReversible && (
        <span className="icon-wrapper" style={{ marginRight: '8px' }}>
          <img src={ArrowIcon} alt="" className="animation-icon" />
        </span>
      )}
      {children}
      {!isReversible && (
        <span className="icon-wrapper" style={{ marginLeft: '8px' }}>
          <img src={ArrowIcon} alt="" className="animation-icon" />
        </span>
      )}
    </RoundGradientLink>
  )
}
