import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useQuery } from 'react-query'
import { styled } from '@mui/material/styles'

import { mediaQuery, useI18n, useNavigate } from '../../functions/hooks'
import { BasicModal, ButtonRound, LinkRound } from '.'
import { BaseText, BaseButtonWrapper, BaseTitle } from '../../functions/themes'
import { getMachiPassLogin } from '../../functions/apis'
import { getSignup } from '../../functions/apis/auth/signup'

const CustomSpan = styled('span')`
  color: #cc3c4d;
  font-weight: bold;
  &.-pointer {
    cursor: pointer;
  }
  &.-link {
    color: #0066ff;
    font-weight: normal;
  }
`
const GapWrapper = styled('div')`
  display: grid;
  gap: 20px;
`
const Block = styled('div')`
  margin: 0 30px;
`

const CustomOnClickFAQ = styled('span')`
  background-color: yellow;
  cursor: pointer;
`

type ContentModalForPublicProps = {
  isOpen: boolean
  close: () => void
}

export const ContentModalForPublic: React.FC<ContentModalForPublicProps> = ({
  isOpen,
  close
}) => {
  const { isJapanese } = useI18n()
  const { pushToQuestion, navigate } = useNavigate()
  const [machipassUri, setMachipassUri] = useState<string>('')
  const location = useLocation()
  const { data: signup } = useQuery('getSignup', getSignup)

  const asyncFunc = async () => {
    const res = await getMachiPassLogin()
    setMachipassUri(res.uri)
  }

  const onClickRegisterForm = () => {
    if (signup) window.location.replace(signup.login_uri)
  }
  const onClickRegisterFormNoId = () => {
    if (signup) window.location.replace(signup.signup_uri)
  }

  const handleMachiPassLogin = () => {
    // machipathログイン後に特定のページに遷移させるために必要
    const path = location.pathname
    localStorage.setItem('redirect_path', path)
    window.location.replace(machipassUri)
  }

  const handleOpenLoginForm = () => {
    navigate('/login', {
      state: { isOpenFlag: true }
    })
  }

  useEffect(() => {
    asyncFunc()
  }, [])

  return (
    <BasicModal
      title={
        isJapanese
          ? '会員様限定のコンテンツ閲覧のためログインが必要です。'
          : 'Login required for members-only content viewing.'
      }
      size="medium"
      open={isOpen}
      handleOpen={close}
      handleCloseWithIcon={close}
      contents={
        <Block>
          <GapWrapper>
            <BaseButtonWrapper>
              <LinkRound onClick={handleMachiPassLogin}>
                {isJapanese ? 'Machi Passでログイン' : 'Login By Machi Pass'}
              </LinkRound>
            </BaseButtonWrapper>
            {isJapanese ? (
              <BaseText className="-red -left">
                ※11月5日以降、本サイトの利用はMachi
                pass発行が必須となりました。MachiPassIDを発行の上、ご登録をお願い致します。
                <CustomOnClickFAQ onClick={pushToQuestion}>
                  詳しくはこちら
                </CustomOnClickFAQ>
              </BaseText>
            ) : (
              <BaseText className="-red -left">
                ※From 1 October onwards, Machipass issuance is mandatory for
                content use; please register with a MachiPassID.
                <CustomOnClickFAQ onClick={pushToQuestion}>
                  Click here for more information.
                </CustomOnClickFAQ>
              </BaseText>
            )}
          </GapWrapper>
        </Block>
      }
      footer={
        <GapWrapper>
          <BaseTitle>
            {isJapanese ? '新規登録' : 'New Member Registration'}
          </BaseTitle>
          <BaseButtonWrapper>
            <ButtonRound onClick={onClickRegisterForm}>
              {isJapanese
                ? 'Machi Pass IDをお持ちの方'
                : 'if registered Machi Pass'}
            </ButtonRound>
            <ButtonRound onClick={onClickRegisterFormNoId}>
              {isJapanese
                ? ' Machi Pass IDをお持ちでない方'
                : 'if not registered Machi Pass'}
            </ButtonRound>
          </BaseButtonWrapper>
        </GapWrapper>
      }
    />
  )
}
